import fetch from 'cross-fetch';

import appConfig from '@nerdwallet/app-config';

/**
 * Possible Apollo Error Messages.
 * @link {https://github.com/NerdWallet/mobile-creditcards/blob/develop/App/Lib/Constants/apollo-constants.ts#L3}
 */
export enum APOLLO_ERROR_MESSAGES {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NO_RESPONSE = 'NO_RESPONSE',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  REQUEST_TIMED_OUT = 'REQUEST_TIMED_OUT',
  NO_CONNECTION = 'NO_CONNECTION',
}

/**
 * Create a timeout Promise and expose its timeout id.
 */
const createTimeout = (
  timeoutMs: number,
  errMessage: string = APOLLO_ERROR_MESSAGES.REQUEST_TIMED_OUT
): {
  promise: Promise<Response>;
  timeoutId?: number;
} => {
  let timeoutId;
  const promise = new Promise<Response>((_resolve, reject) => {
    timeoutId = setTimeout(() => reject(new Error(errMessage)), timeoutMs);
  });
  return { promise, timeoutId };
};

/**
 * Default timeout of 10s.
 */
const DEFAULT_REQUEST_TIMEOUT_MS = 10000;

/**
 * Lightweight fetch with custom timeout handling.
 * @see {@link https://github.com/NerdWallet/mobile-creditcards/blob/develop/App/Apollo/links/httpLink.ts#L15-L44}
 */
const customFetch = (
  input: RequestInfo,
  init?: Omit<RequestInit, 'signal'>
): Promise<Response> => {
  const controller = new AbortController();
  const { signal } = controller;
  const { promise: timeoutPromise, timeoutId } = createTimeout(
    /* istanbul ignore else */
    appConfig.REQUEST_TIMEOUT_MS ?? DEFAULT_REQUEST_TIMEOUT_MS
  );
  return Promise.race([
    timeoutPromise.catch((err) => {
      controller.abort();
      throw err;
    }),
    fetch(input, { ...init, signal }).then((response) => {
      clearTimeout(timeoutId);
      return response;
    }),
  ]);
};
export default customFetch;

// eslint-disable-next-line no-underscore-dangle
export const __testing = {
  createTimeout,
};
