/**
 * Route definitions. Entry point to all routes.
 */

import React from 'react';
import { Route } from 'react-router';

import Container, { ReactRouterChildrenProps } from './container';

const routes = (
  <Route
    path="/"
    render={({ match, location, history }) => (
      <Container
        match={match as ReactRouterChildrenProps['match']}
        history={history}
        location={location}
      />
    )}
  />
);

export default routes;
