import React from 'react';
import { Route, RouteChildrenProps, Switch } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import universal from '@nerdwallet/coin/universal';

const DemoPage = universal(import('../components/DemoPage/index'));
const StructuredContentPage = universal(
  import('../components/StructuredContentPage/index')
);
const GlobalNavTest = universal(import('../components/GlobalNavTest/index'));

const AppRoutes: React.FC<
  RouteChildrenProps<{
    slug: string;
  }>
> = (props: any) => (
  <Switch>
    <Route
      path="/d/global-nav-test"
      render={() => <GlobalNavTest {...props} />}
    />
    <Route path="/d/*" render={() => <DemoPage {...props} />} />
    <Route render={() => <StructuredContentPage {...props} />} />
  </Switch>
);

export default AppRoutes;
