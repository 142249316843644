import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import initBaseStyles from '@nerdwallet/base-styles/init';
import { initBrowserEvents } from '@nerdwallet/analytics';
import routeDefinitions from './routes';

import '@nerdwallet/base-styles/reset.css';
import '@nerdwallet/base-styles/base.css';

initBaseStyles();
// Enable automatic tracking of navigation events
initBrowserEvents();

export default (/* { store } */): React.ReactNode => (
  <Router>{routeDefinitions}</Router>
);
