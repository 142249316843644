/**
 * This is a container for all route handlers. It can be simple and
 * dumb as is (passes route params down to RouteHandler),
 * but allows for an extra layer of control over all nested routes in needed.
 */

import React from 'react';
import { RouteChildrenProps } from 'react-router';
import AppRoutes from './app-routes';

export type RouterPathParams = {
  slug: string;
};

export type ReactRouterChildrenProps = RouteChildrenProps<RouterPathParams>;

const Container: React.FC<ReactRouterChildrenProps> = (props) => {
  return (
    <div className="route-container">
      <AppRoutes {...props} />
    </div>
  );
};

Container.displayName = 'RouteContainer';

export default Container;
