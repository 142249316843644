
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "StructuredContentPageInterface": [
      "SearchResultsPage",
      "SCArticleFlexPage",
      "ArticlePage",
      "SmbArticlePage",
      "AuthorPage",
      "ExactPathSCCalculatorPage",
      "SCCalculatorPage",
      "CreditCardsRoundupsPage",
      "ContentHubPage",
      "AutoInsurancePage",
      "AutoInsuranceVehiclePage",
      "UrlRecordCalcPage",
      "ReviewerPage",
      "CreditCardsReviewPage",
      "SmbReviewPage",
      "SCFlexPage"
    ],
    "MarketplaceEntityWithPlacementInterface": [
      "InsuranceOfferV2",
      "InvestingOfferV2",
      "CreditCardsOfferV2",
      "BankingOfferV2",
      "EducationLoansOfferV2",
      "MortgagesOfferV2",
      "SoftwareOfferV2",
      "AutoLoansOfferV2",
      "EstatePlanningOfferV2",
      "ExpansionOfferV2",
      "BankingInstitutionV2",
      "PersonalLoansOfferV2",
      "SmallMediumBusinessLoansOfferV2"
    ],
    "MarketplaceEntityWithStarRatingInterface": [
      "InsuranceOfferV2",
      "InvestingOfferV2",
      "InsuranceInstitutionV2",
      "CreditCardsOfferV2",
      "BankingOfferV2",
      "EducationLoansOfferV2",
      "MortgagesOfferV2",
      "SoftwareOfferV2",
      "AutoLoansOfferV2",
      "EstatePlanningOfferV2",
      "ExpansionOfferV2",
      "BankingInstitutionV2",
      "EducationLoansInstitutionV2",
      "InvestingInstitutionV2",
      "PersonalLoansOfferV2",
      "SmallMediumBusinessLoansOfferV2"
    ],
    "BaseField": [
      "AnnualIncome",
      "Age"
    ],
    "EvertreeResponse": [
      "EvertreeSuccessResponse",
      "EvertreeErrorResponse"
    ],
    "FenrisProfileDataResponse": [
      "FenrisProfileDataSuccessResponse",
      "FenrisProfileDataErrorResponse"
    ],
    "CoveredQuoteResponse": [
      "CoveredQuoteSuccessResponse",
      "CoveredQuoteErrorResponse"
    ],
    "MarketplaceEntityInterface": [
      "InsuranceOfferV2",
      "InvestingOfferV2",
      "InsuranceProductV2",
      "InsuranceInstitutionV2",
      "CreditCardsOfferV2",
      "BankingOfferV2",
      "EducationLoansOfferV2",
      "MortgagesOfferV2",
      "SoftwareOfferV2",
      "AutoLoansOfferV2",
      "EstatePlanningOfferV2",
      "ExpansionOfferV2",
      "AutoLoansInstitutionV2",
      "AutoLoansProductV2",
      "BankingInstitutionV2",
      "BankingProductV2",
      "CreditCardsInstitutionV2",
      "CreditCardsProductV2",
      "EducationLoansInstitutionV2",
      "EducationLoansProductV2",
      "EstatePlanningInstitutionV2",
      "EstatePlanningProductV2",
      "ExpansionInstitutionV2",
      "ExpansionProductV2",
      "InvestingInstitutionV2",
      "InvestingProductV2",
      "MortgagesInstitutionV2",
      "MortgagesProductV2",
      "PersonalLoansInstitutionV2",
      "PersonalLoansOfferV2",
      "PersonalLoansProductV2",
      "SmallMediumBusinessLoansInstitutionV2",
      "SmallMediumBusinessLoansOfferV2",
      "SmallMediumBusinessLoansProductV2",
      "SoftwareInstitutionV2",
      "SoftwareProductV2",
      "ThirdPartyEmbedsInstitution",
      "ThirdPartyEmbedsProduct"
    ],
    "MarketplaceEntityWithBestOfAwards2021Interface": [
      "InsuranceOfferV2",
      "InvestingOfferV2",
      "CreditCardsOfferV2",
      "BankingOfferV2",
      "EducationLoansOfferV2",
      "MortgagesOfferV2",
      "PersonalLoansOfferV2"
    ],
    "MarketplaceEntityWithBestOfAwardsInterface": [
      "InsuranceOfferV2",
      "InvestingOfferV2",
      "CreditCardsOfferV2",
      "BankingOfferV2",
      "EducationLoansOfferV2",
      "MortgagesOfferV2",
      "SoftwareOfferV2",
      "AutoLoansOfferV2",
      "PersonalLoansOfferV2",
      "SmallMediumBusinessLoansOfferV2"
    ],
    "MarketplaceEntityWithReviewInterface": [
      "InsuranceOfferV2",
      "InvestingOfferV2",
      "InsuranceProductV2",
      "CreditCardsOfferV2",
      "BankingOfferV2",
      "EducationLoansOfferV2",
      "MortgagesOfferV2",
      "SoftwareOfferV2",
      "AutoLoansOfferV2",
      "EstatePlanningOfferV2",
      "ExpansionOfferV2",
      "BankingInstitutionV2",
      "InvestingProductV2",
      "PersonalLoansOfferV2",
      "SmallMediumBusinessLoansOfferV2"
    ],
    "MarketplaceOfferInterface": [
      "InsuranceOfferV2",
      "InvestingOfferV2",
      "CreditCardsOfferV2",
      "BankingOfferV2",
      "EducationLoansOfferV2",
      "MortgagesOfferV2",
      "SoftwareOfferV2",
      "AutoLoansOfferV2",
      "EstatePlanningOfferV2",
      "ExpansionOfferV2",
      "PersonalLoansOfferV2",
      "SmallMediumBusinessLoansOfferV2"
    ],
    "MarketplaceProductInterface": [
      "InsuranceProductV2",
      "AutoLoansProductV2",
      "BankingProductV2",
      "CreditCardsProductV2",
      "EducationLoansProductV2",
      "EstatePlanningProductV2",
      "ExpansionProductV2",
      "InvestingProductV2",
      "MortgagesProductV2",
      "PersonalLoansProductV2",
      "SmallMediumBusinessLoansProductV2",
      "SoftwareProductV2"
    ],
    "MarketplaceInstitutionInterface": [
      "InsuranceInstitutionV2",
      "AutoLoansInstitutionV2",
      "BankingInstitutionV2",
      "CreditCardsInstitutionV2",
      "EducationLoansInstitutionV2",
      "EstatePlanningInstitutionV2",
      "ExpansionInstitutionV2",
      "InvestingInstitutionV2",
      "MortgagesInstitutionV2",
      "PersonalLoansInstitutionV2",
      "SmallMediumBusinessLoansInstitutionV2",
      "SoftwareInstitutionV2"
    ],
    "FeatureDiscoveryModuleInterface": [
      "FeatureDiscoveryFullScreenModule",
      "FeatureDiscoveryContextualSectionListPopupModule"
    ],
    "FinancialNwPlusRewardInterface": [
      "FinancialCreditCardReward",
      "FinancialBankingReward"
    ],
    "FinancialNwPlusRewardEventInterface": [
      "FinancialCreditCardRewardEvent",
      "FinancialBankingRewardEvent"
    ],
    "OfferRecommendation": [
      "SavingsOfferRecommendation"
    ],
    "CostOfLivingCityFields": [
      "CostOfLivingCity",
      "CostOfLivingCityWithCityLife"
    ],
    "AuthenticateCreditProfileResponseUnion": [
      "TuCreditReportIdentityProfile",
      "CreditProfileAuthStatusProfile"
    ],
    "CreditProfileAuthResponseUnion": [
      "CreditProfileAuthStatusProfile",
      "CreditProfileAuthResponseError"
    ],
    "MarketplaceEntityRelatedReviewUnion": [
      "Review",
      "ReviewRedirect"
    ],
    "ContentPackItemsUnion": [
      "Article",
      "ContentHub",
      "FlexPage",
      "Hub",
      "LandingPage",
      "Page",
      "Post",
      "Review",
      "Roundup"
    ],
    "ContentSearchResultRelatedContentTypeUnion": [
      "Article",
      "CalculatorPage",
      "ContentHub",
      "FlexPage",
      "Hub",
      "InsurancePage",
      "LandingPage",
      "Page",
      "Post",
      "Review",
      "Roundup",
      "UrlRecord"
    ],
    "ContentUserPageContentPicksUnion": [
      "Article",
      "ContentHub",
      "FlexPage",
      "Hub",
      "LandingPage",
      "Page",
      "Post",
      "Review",
      "Roundup"
    ],
    "GlobalNavItemItemLinkUnion": [
      "Article",
      "CalculatorPage",
      "ContentHub",
      "FlexPage",
      "Hub",
      "InsurancePage",
      "LandingPage",
      "Page",
      "Post",
      "Review",
      "Roundup",
      "UrlRecord"
    ],
    "GlobalNavItemMoreInfoLinkUnion": [
      "Article",
      "CalculatorPage",
      "ContentHub",
      "FlexPage",
      "Hub",
      "InsurancePage",
      "LandingPage",
      "Page",
      "Post",
      "Review",
      "Roundup",
      "UrlRecord"
    ],
    "HubLinkUnion": [
      "Article",
      "CalculatorPage",
      "ContentHub",
      "FlexPage",
      "Hub",
      "InsurancePage",
      "LandingPage",
      "Page",
      "Post",
      "Review",
      "Roundup",
      "UrlRecord"
    ],
    "LegacyLenderReviewUnion": [
      "Post",
      "Review"
    ],
    "RoundupFooterPostsUnion": [
      "Article",
      "Page",
      "Post",
      "Roundup"
    ],
    "AuthenticationResponseInterface": [
      "RegisterUserResponse",
      "LogInUserResponse"
    ],
    "MembershipFeatureActivationStatusInterface": [
      "CreditScoreActivationStatusResponse",
      "CashFlowActivationStatusResponse",
      "NetWorthActivationStatusResponse",
      "EmailOnlyRegisteredUserActivationStatusResponse"
    ],
    "UserInsightDataVisualizationInterface": [
      "SpendingByMonthsDataVisualization",
      "SpendingByTopCategoriesDataVisualization",
      "CreditScoreActivationVisualization",
      "FinancialAccountsActivationVisualization",
      "MyCardsActivationVisualization",
      "CreditBoosterDataVisualization",
      "WhyDidMyScoreChangeDataVisualization"
    ],
    "UserInsightInterface": [
      "FeedCardInsight",
      "RecommendationInsight",
      "FinancialInsight",
      "CreditScoreInsight"
    ],
    "InstitutionInterface": [
      "CreditCardInstitution",
      "InvestmentAccountInstitution",
      "PersonalLoanInstitution",
      "SavingsAccountInstitution"
    ],
    "OfferInterface": [
      "CreditCardOffer",
      "InvestmentAccountOffer",
      "PersonalLoanOffer",
      "SavingsAccountOffer"
    ],
    "AccountOfferInterface": [
      "InvestmentAccountOffer",
      "SavingsAccountOffer"
    ],
    "ProductInterface": [
      "CreditCardProduct",
      "PersonalLoanProduct",
      "MortgageProduct"
    ],
    "MarketplaceEntityWithQualificationsInterface": [
      "EducationLoansOfferV2",
      "AutoLoansOfferV2",
      "PersonalLoansOfferV2",
      "SmallMediumBusinessLoansOfferV2"
    ],
    "NetWorthRealAsset": [
      "NetWorthHomeIntegrated",
      "NetWorthRealAssetSelfReported"
    ],
    "NetWorthHomeValueResultResponseDataInterface": [
      "NetWorthHomeValueResultResponseData",
      "NetWorthHomeValuePossibilityResponseData"
    ],
    "PrequalOffer": [
      "AutoInsurancePrequalOffer"
    ],
    "PostInterface": [
      "InsurancePage",
      "Post",
      "Page",
      "Article",
      "LandingPage",
      "Review",
      "Roundup",
      "EngineeringPost",
      "Table",
      "LegacyLender",
      "DynamicPlacement",
      "Block",
      "BlockGroup",
      "HouseAd",
      "UrlRecord",
      "ContentReviewer",
      "ContentUserPage",
      "BccPageCard",
      "BccPage",
      "GlobalNavItem",
      "Hub",
      "ContentHub",
      "StructuredContentCalculator",
      "ContentPack",
      "PressRelease",
      "MarketplaceEntryPoint",
      "CalculatorPage",
      "FlexPage",
      "FlexTemplate",
      "MediaItem",
      "ContentSearchResult"
    ],
    "PostWithAuthorsInterface": [
      "InsurancePage",
      "Post",
      "Page",
      "Article",
      "LandingPage",
      "Review",
      "Roundup",
      "EngineeringPost",
      "LegacyLender",
      "DynamicPlacement",
      "Block",
      "BlockGroup",
      "HouseAd",
      "UrlRecord",
      "ContentReviewer",
      "ContentUserPage",
      "BccPageCard",
      "BccPage",
      "Hub",
      "ContentHub",
      "StructuredContentCalculator",
      "ContentPack",
      "PressRelease",
      "MarketplaceEntryPoint",
      "CalculatorPage",
      "FlexPage",
      "FlexTemplate",
      "MediaItem",
      "ContentSearchResult"
    ],
    "PostWithChatbotInterface": [
      "Article"
    ],
    "PostWithContentClassificationInterface": [
      "InsurancePage",
      "Article",
      "LandingPage",
      "Review",
      "Roundup",
      "EngineeringPost",
      "UrlRecord",
      "BccPage",
      "Hub",
      "ContentHub",
      "StructuredContentCalculator",
      "CalculatorPage",
      "FlexPage"
    ],
    "PostWithContentInterface": [
      "InsurancePage",
      "Article",
      "Review",
      "EngineeringPost",
      "Table",
      "DynamicPlacement",
      "Block",
      "ContentHub",
      "CalculatorPage",
      "FlexTemplate"
    ],
    "PostWithEditorInterface": [
      "Article",
      "LandingPage",
      "Review",
      "Roundup",
      "EngineeringPost",
      "UrlRecord",
      "BccPage",
      "CalculatorPage",
      "FlexPage"
    ],
    "PostWithExcerptInterface": [
      "Article",
      "Review",
      "Roundup",
      "EngineeringPost",
      "Table"
    ],
    "PostWithFAQsInterface": [
      "Article",
      "Review",
      "Roundup",
      "UrlRecord",
      "BccPage",
      "CalculatorPage",
      "FlexPage"
    ],
    "PostWithFeaturedImageInterface": [
      "InsurancePage",
      "Post",
      "Page",
      "Article",
      "LandingPage",
      "Review",
      "Roundup",
      "EngineeringPost",
      "LegacyLender",
      "HouseAd",
      "UrlRecord",
      "ContentReviewer",
      "ContentUserPage",
      "Hub",
      "ContentHub",
      "PressRelease",
      "MarketplaceEntryPoint",
      "CalculatorPage",
      "FlexPage",
      "ContentSearchResult"
    ],
    "PostWithInternationalVersionsInterface": [
      "Article",
      "Review",
      "Roundup"
    ],
    "PostWithPageTaxonomyInterface": [
      "Article",
      "Review",
      "Roundup",
      "CalculatorPage",
      "FlexPage"
    ],
    "PostWithReviewerInterface": [
      "Article",
      "Review",
      "Roundup",
      "UrlRecord",
      "BccPage",
      "CalculatorPage",
      "FlexPage"
    ],
    "PostWithSEOInterface": [
      "InsurancePage",
      "Article",
      "LandingPage",
      "Review",
      "Roundup",
      "EngineeringPost",
      "UrlRecord",
      "ContentReviewer",
      "ContentUserPage",
      "BccPage",
      "Hub",
      "ContentHub",
      "CalculatorPage",
      "FlexPage"
    ],
    "PostWithTitleInterface": [
      "InsurancePage",
      "Post",
      "Page",
      "Article",
      "LandingPage",
      "Review",
      "Roundup",
      "EngineeringPost",
      "Table",
      "LegacyLender",
      "DynamicPlacement",
      "Block",
      "BlockGroup",
      "HouseAd",
      "UrlRecord",
      "ContentReviewer",
      "ContentUserPage",
      "BccPageCard",
      "BccPage",
      "GlobalNavItem",
      "Hub",
      "ContentHub",
      "StructuredContentCalculator",
      "ContentPack",
      "PressRelease",
      "MarketplaceEntryPoint",
      "CalculatorPage",
      "FlexPage",
      "FlexTemplate",
      "MediaItem",
      "ContentSearchResult"
    ],
    "TermInterface": [
      "Category",
      "MediaSite"
    ],
    "PostWithHubLinks": [
      "Article",
      "ContentHub"
    ],
    "ContentPageHeaderInterface": [
      "Article",
      "Review",
      "Roundup",
      "UrlRecord",
      "CalculatorPage",
      "FlexPage",
      "FlexTemplate"
    ],
    "PostWithContactInfoInterface": [
      "ContentReviewer",
      "ContentUserPage"
    ],
    "PostWithUserInfoInterface": [
      "ContentReviewer",
      "ContentUserPage"
    ]
  }
};
      export default result;
    