/* eslint-disable no-underscore-dangle */
import appConfig from '@nerdwallet/app-config';

import generatedPossibleTypes from '../../generated-gql/possible-types';

import fetch from './apollo/fetch';

const { possibleTypes } = generatedPossibleTypes;

/**
 * This is needed when Structured Content component.
 * @todo Investigate more sustainable strategies for type normalization on data from different entrypoints.
 * Long term we shouldn't clutter the global namespace w/ "app" specific info.
 */
const typePolicies = {
  Author: {
    merge: true,
  },
  Review: {
    merge: true,
  },
  EntityPlacementDriver: {
    keyFields: ['label', 'value'],
  },
  EntityPlacementImage: {
    keyFields: ['alt'],
    merge: true,
  },
};

export default {
  connectToDevTools: process.browser && appConfig?._meta?.environment === 'dev',
  defaults: {},
  fetch,
  possibleTypes,
  resolvers: {},
  typePolicies,

  // New options for v5
  supergraphURL: appConfig?.API?.BASE_URLS?.SUPERGRAPH,
  name: appConfig?.CLIENT_ID,
  environment: appConfig?._meta?.environment,
  version: appConfig?._meta?.version,
};
